<template>
  <v-container fluid>
    <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-alert"
    >
      Atenção: utilize a sintaxe SQL do banco de dados relacional Postgres.
    </v-alert>
    <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-alert"
    >
      Atenção: para evitar consultas retornando dados errados, por favor
      lembre-se de em caso de consultas diretas em tabelas de entidades da BDGD,
      informar a coluna bdgd_versao_id = {{ versaoBdgdMaisRecente.id }}
      (ou o ID da versão desejada) em seus filtros e junções de tabelas.
    </v-alert>
    <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-alert"
    >
      Atenção: siga os exemplos de consultas caso tenha dúvidas em como escrever
      as querys ou entre em contato com a equipe de desenvolvedores do sistema
      para receber ajuda.
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'AlertConsultasCustomizadas',
  props: {
    versaoBdgdMaisRecente: {
      type: Object,
      required: true,
      default: () => 1
    }
  }
};
</script>
